<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				hero:{},
				waterProduct:{},
				filter:{sort:'asc'},
				Pagination:{},				
				containerId:''
            }
		},
		
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.apiGetProduct()
            this.apiGetImageHeader()
            if(this.$route.query.sort){
            	this.filter.sort = this.$route.query.sort
            }
		},
        methods: {
        	apiGetProduct(){
        		Gen.apirest("/water-product",Gen.clone(this.$route.query),(err,resp)=>{
                    if(err) console.log(err)
                    this.waterProduct = resp.data.data
                    this.Pagination = resp.data
                    setTimeout(()=>{
						this.$redrawVueMasonry.apply()
					},500)
                })
        	},
        	apiGetImageHeader(){
        		Gen.apirest("/water-hero",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.hero = resp.data
                	setTimeout(() => {
						SEMICOLON.documentOnLoad.init()
					}, 400)
                })
        	},
        	onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
        },
        watch:{
        	'$route.query'(v){
				this.apiGetProduct()
			},
        	'filter.sort'(v){
				this.$router.push({
                    path: this.$route.path, 
                    query: Object.assign(Gen.clone(this.$route.query),{sort: v})
                })
			}
        }
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element boxed-slider notoppadding">
	        <div class="fslider" data-touch="false" data-easing="easeInQuad">
	            <div class="flexslider">
	                <div class="slider-wrap">
						<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.ash_image_desktop)">
							<img :src="uploader(hero.ash_image_desktop)" :alt="hero['ash_title_'+hl]" :title="hero['ash_title_'+hl]">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero['ash_title_'+hl] }}</h2>
								<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.ash_sub_title_en">{{ hero['ash_sub_title_'+hl] }}</p>							
							</div>
						</div>
						<div v-else class="slide" :data-thumb="uploader(hero.ash_image_mobile)">
							<img :src="uploader(hero.ash_image_mobile)" :alt="hero['ash_title_'+hl]" :title="hero['ash_title_'+hl]">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero['ash_title_'+hl] }}</h2>
							</div>
						</div>
					</div>
	            </div>
	        </div>
	    </section>
		<section id="content" style="background-color: #000; overflow: visible">
		    <div class="content-wrap pt-0 pb-0">
		        <div id="section-about" class="section page-section bg_white  nobottommargin notopmargin clearfix">
		            <div class="container clearfix">
		                <div class="row justify-content-around">
		                    <div class="col-lg-7 col-md-6">
		                        <ol class="breadcrumb">
		                            <li class="breadcrumb-item"><router-link :to="{name:'Home'}">Home</router-link></li>
		                            <li class="breadcrumb-item active" aria-current="page">Water</li>
		                        </ol>
		                    </div>
		                    <div class="col-md-3 d-none d-md-block">
		                        <div class="txt_sort_by">
		                            <p>{{web.lbl_sort_by}}</p>
		                        </div>
		                    </div>
		                    <div class="col-lg-2 col-md-3 pl-md-0 d-none d-md-block">
		                        <div class="form-group select_custom_dark">
		                            <select v-model="filter.sort" id="template-contactform-service" name="template-contactform-service"
		                                class="sm-form-control frm_custom_filter valid">
		                                <option value="asc">A-Z</option>
		                                <option value="desc">Z-A</option>
		                                <option value="favorite">{{web.lbl_favorite}}</option>
		                            </select>
		                        </div>
		                    </div>
		                </div>
		                <div class="row">
		                    <div class="col-md-12 col-lg-12">
		                    	<div v-if="waterProduct.length">
		                        	<div v-masonry="containerId" transition-duration="0.3s" item-selector=".item" class="row tight" v-if="isMobile">
			                        	<div class="col-md-6 col-lg-3 col-6 item" v-masonry-tile  v-for="(v,k) in waterProduct" :key="k">
		                                    <div class="wrap_prod_list">
		                                        <div class="wrap_prod_thumb">
		                                            <router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
		                                            	<img :src="uploader(v.apd_image)" :alt="v.apd_name" :mobile-img-src="uploader(v.apd_image)"/>
		                                            </router-link>
		                                             <a href="javascript:;" :id="'fav_'+v.apd_id" @click="addFavorite(v)" class="btn_fav">
		                                            	<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
		                                            	<i v-else class="icon-line-heart"></i>
		                                            </a>
		                                        </div>
		                                        <div class="prod_desc">
		                                            <h3><router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">{{v.apd_name}}</router-link></h3>
													<h4>{{v.apd_year}}</h4>
		                                            <p>Rp {{v.price}}</p>
		                                            <div class="rating_prod">
		                                                <i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                            </div>
		                            <div class="row tight" v-else>
			                        	<div class="col-md-4 col-lg-3 col-6" v-for="(v,k) in waterProduct" :key="k">
		                                    <div class="wrap_prod_list">
		                                        <div class="wrap_prod_thumb">
		                                            <router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
		                                            	<img :src="uploader(v.apd_image)" :alt="v.apd_name" :mobile-img-src="uploader(v.apd_image)"/>
		                                            </router-link>
		                                             <a href="javascript:;" :id="'fav_'+v.apd_id" @click="addFavorite(v)" class="btn_fav">
		                                            	<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
		                                            	<i v-else class="icon-line-heart"></i>
		                                            </a>
		                                        </div>
		                                        <div class="prod_desc">
		                                            <h3><router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">{{v.apd_name}}</router-link></h3>
													<h4>{{v.apd_year}}</h4>
		                                            <p>Rp {{v.price}}</p>
		                                            <div class="rating_prod">
		                                                <i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                            </div>
	                                <div class="clearfix"></div>
	                                <div class="col-12 justify-content-center">
		                                <Pagination class="justify-content-center mt-5" :data="Pagination" :limit="3" @pagination-change-page="onPaging"></Pagination>
		                            </div>
		                        </div>
		                        <div class="row tight" v-else>
	                            	<div class="col-12 text-center"><h4>{{$route.query.search ? web.no_search_result+$route.query.search : web.lbl_no_product}}</h4></div>
	                            </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
		    <div class="clear"></div>
		</section>
	</div>
</template>